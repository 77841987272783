module.exports.messages = {
  welcome: 'Bienvenue!',
  validationx:'Validation des frais de départ',
  TransTickets:'Votre transaction a',
  MaxTickets:'Limite de billets par transaction',
  TokenInvalid:'Login or Register!',
  SelectTickets:'Sélectionnez un billet!',
  generateorden:'Génération de la commande',
  finishtransac:'Finalisation de la transaction',
  confirmpay:'Confirmation du paiement',
  invalidUser: 'Utilisateur invalide!',
  desactivate: "Désactivé pour l'instant",
  failedToLogin: 'Échec de la connexion!',
  ticketsNumberMustZero: 'Le nombre de billets doit être supérieur à zéro',
  ticketsNumberMustLessHundred: 'Le nombre de billets doit être inférieur à cent',
  checkTheFormData: 'Vérifier les données du formulaire',
  acceptYermsConditions: 'Accepter les termes et conditions',
  validation: {
    required: 'Ce champ est obligatoire.',
    isEmail: "L'email doit être valide.",
    validCard: 'Carte invalide',
    isPhone: 'Le téléphone est invalide',
    validLocation: 'Veuillez sélectionner un emplacement parmi les options',
    invalidFields: 'Champs Invalides',
    ValidateReCAPTCHA: 'Valider le reCAPTCHA',
    invalidCode: 'Code Invalide',
    InvalidEmailPassword: 'Email ou mot de passe invalide'
  },
  tarjetValid:'Carte valide',
  permissionsRequired: 'Autorisations requises.',
  needEmailMsg: 'Email (requis). Voulez-vous accorder des autorisations?',
  tryAgain: 'Réessayer',
  canceltickets:'Voulez-vous supprimer des billets de ce site?',
  UpdateInfoUser:'Données mises à jour',
  msgConfirmation1:"¡IMPRIMEZ VOS BILLETS 48 HEURES APRÈS VOTRE ACHAT! (avant cette heure, vous ne pourrez pas les imprimer) et jusqu'à 72 heures avant l'événement en vous connectant à votre compte Ticket2pass.",
  updatedPass: "Mot de passe mis à jour",
  correctCode: "Code correct. Entrez un nouveau mot de passe."
}