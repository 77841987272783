import { mapGetters } from "vuex";

export const EVENTMIXIN = {
  data() {
    return {};
  },
  methods: {
    ...mapGetters({
      gettypeSitesMix: "catalogs/gettypeSites",
      getEventConfigMix: "catalogs/getEventConfig",
    }),
    SettypeSitesMix(eventCategories) {
      this.$store.dispatch("catalogs/cleantypeSite");
      this.$store.dispatch("catalogs/triggertypeSite", eventCategories);
    },
    setAllPlacesMixin(places) {
      this.$store.dispatch("catalogs/cleanallPlace");
      this.$store.dispatch("catalogs/setAllPlaces", places);
    },

    getCorps_Mixin() {
      this.$root
        .post("/Admin/getCorps")
        .then((response) => {
          let info = response.data;
          if (info.success === "TRUE") {
            this.$store.dispatch("auth/setSelectedCorp", info.Data[0].idcorp);
            this.$store.dispatch("auth/setCorps", info.Data);
          } else {
            this.$root.swalAlert("warning", info.Msg);
          }
        })
        .catch((e) => {
          this.$root.swalAlert(
            "error",
            "Error al cargar las corporaciones",
            e.message
          );
        })
        .finally(() => {
        });
    },
    LoadPlace_Mixin() {
      return new Promise((resolve, reject) => {
        this.$root.post("/Admin/place", {}).then((response) => {
          let info = response.data;
          if (info.success == "TRUE") {
            this.SettypeSitesMix(info.cata);
            this.setAllPlacesMixin(info.place);
            this.$root.persmise = true;
          } else {
            let text =
              "No tiene ningun sitio configurado sitios de los eventos";
            if (info.Msg) {
              text = info.Msg;
            }
            this.SettypeSitesMix([]);
            this.setAllPlacesMixin([]);
          }
          resolve(true);
        })
        .catch(e => {
            this.$root.swalAlert("warning", "", e.message); 
        });
      });
    },
  },
};
