export const auth = {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")),
    auth: false,
    corps: [],
    id_corp_selected: null
  },
  actions: {
    login({ commit }, user) {
      commit("login_M", user);
    },
    logOut({ commit }) {
      commit("loginOut_M");
    },
    setCorps({ commit }, corps) {
      commit("setCorps_M", corps);
    },
    setSelectedCorp({ commit }, id_corp) {
      commit("setSelectedCorp_M", id_corp);
    }
  },
  mutations: {
    login_M(state, user)  {
      localStorage.setItem("user", JSON.stringify(user));
      state.user = user;
      state.auth = true
    },
    loginOut_M(state) {
      localStorage.removeItem("user")
      state.user = null
    },
    setCorps_M(state, corps) {
      state.corps = corps
    },
    setSelectedCorp_M(state, id_corp) {
      state.id_corp_selected = id_corp
    }
  },
  getters: {
    authorized(state){
      return !!state.user
    }
  }
};
