module.exports.actions = {
  categorias:[
    {name:'Sites touristiques'},
    {name:'Événements'}
  ],
  tickets:'Billet',
  packets:'Paquets',
  price:'Prix',
  amount:'Montant',
  place:'Lieu',
  SeeTickets:'Voir les billets',
  logIn: 'Connexion',
  logInFacebook: 'Se connecter avec Facebook',
  Logout: 'Déconnexion',
  signIn: 'Connexion',
  next: 'Suivant',
  seeTickets: 'voir les billets',
  goBack: 'Retour',
  cancel: 'Annuler',
  save: 'Enregistrer',
  change: 'Modifier',
  toClose: 'Pour fermer',
  search: 'Chercher',
  printTickets: 'Imprimer les billets',
  understand: 'Événements à venir', 
  nextEvent:'Upcoming Events',
  findTicket:'Trouver un billet',
  Events:'Evénements',
  EventS2:'ÉVÉNEMENTS',
  limitedAvai:'Disponible',
  limitedAvai2:'Épuisé',
  about:'à propos',
  ticketFor:'Billet pour',
  limitEdad:"Limite d'âge : Il n'y a aucune restriction. Ils paient un ticket à partir de : 3 ans. Restrictions : L'entrée avec de la nourriture, des boissons, des caméras vidéo... est interdite.",
  cardinfo:{
    tickets:'Billet(s)',
    ticketsUp: 'BILLET(S)',
    section:'SECTION',
    row:'LIGNE',
    colum:'COLONNE',
    seat:'SEAT',
    unitPrice: 'PRIX UNITÉ',
    unitPrice2: 'Prix unitaire',
    price:'PRIX',
    total: 'TOTAL',
    subtotal: 'SOUS-TOTAL',
    amount:'MONTANT',
    numberTickets: "Nombre de billets"
  },
  step:{
    usuario:'Données utilisateur',
    pay:'Détails du paiement',
    confirma:"Confirmation d'achat",
    confirma1:'CONFIRMER',
    confirma2:'ACHAT',
  },
  paycard:{
    card:'N° de carte',
    exp:'Expiration',
    requi:'Ce champ est obligatoire',
    terminos:"Conditions d'utilisation",
    read:"J'ai lu le",
  },
  pdf:{
    oops:"Oups ! Votre navigateur ne prend pas en charge les PDF !",
    download:'Télécharger le fichier'
  },
  next:'Suivant',
  shop:'ACHETER',
  information: 'Informations',
  more:"Plus d'informations",
  address: "Informations sur l'adresse",
}