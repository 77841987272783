import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth-module';
import { catalogs } from './catalogs'
import { events } from './events-module'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    catalogs,
    events
  },
})
