import Vue from "vue";

Vue.prototype.$Entorno = {
  Dashboard: [
    {
      id: 1,
      title: "Mis Eventos",
      subtitle: "Módulo de creación o modificación de eventos",
      icon: "mdi-stadium",
      background: "#4caf50",
      colorIcon: "#80e27e",
    },
    {
      id: 2,
      title: "Reportes",
      subtitle:
        "Módulo de reportería y estadísticas de ventas de los eventos",
      icon: "mdi-television-guide",
      background: "#ff0058",
      colorIcon: "#c30043",
    },
    {
      id: 3,
      title: "Configuración",
      subtitle:
        "Módulo de configuración para los datos de NetPay",
      icon: "mdi-cog-outline",
      background: "#e64a19",
      colorIcon: "#993a1d",
    },
  ],
};
