module.exports.tableHeaders = {
  rowsporpage: 'Lignes par page',
  itemsPerPageAllText: 'Tous',
  eventName: "Nom de l'événement",
  city: 'Ville',
  state: 'État',
  eventPlace: "Lieu de l'événement",
  zone: 'Zone',
  Seat: 'Siège',
  row: 'Ligne',
  column: 'Colonne',
  price: 'Prix',
  transac:'TRANSACTION',
  dtransac:'DATE TRANSACTION',
  ttransac:'TEMPS DE TRANSACTION',
  ResumShop:'Récapitulatif des achats',
  Amount:'Montant',
  Nomber:'Nom du Billet',
  Unitary:'Coût Unitaire',
  subtotal:'Sous-total',
  DataPay:'Données de paiement',
}