export const events = {
  namespaced: true,
  state: {
    eventSelected: {
      id_event: null,
      event_config: null,
      id_place: null
    },
    event: {
      id_event: false,
      name: false
    },
    currentCatalogeSelected: null,
  },
  actions: {
    setEventSelected_A({ commit }, info) {
      commit("setEventSelected_M", info);
    },
    setCurrentCataloge_A({ commit }, cataloge) {
      commit("setCurrentCataloge_M", cataloge);
    },
    setInfoEventSelected_A({ commit }, eventX) {
      commit("setInfoEventSelected_M", eventX);
    },
    resetCataloge_A({ commit }) {
      commit("resetCataloge_M");
    },
    resetBothSelections_A({ commit }) {
      commit("resetBothSelections_M");
    }
  },
  mutations: {
    setEventSelected_M(state, eventInfo) {
      state.eventSelected.id_event = eventInfo.id_event;
      state.eventSelected.event_config = eventInfo.event_config;
      state.eventSelected.id_place = eventInfo.id_place;
    },
    setCurrentCataloge_M(state, id_cataloge) {
      state.currentCatalogeSelected = id_cataloge;
    },
    setInfoEventSelected_M( state, eventX ) {
      state.event = {...eventX}
    },
    resetBothSelections_M(state) {
      state.eventSelected = {
        id_event: null,
        event_config: null,
        id_place: null
      };
      state.event = {
        id_event: false,
        name: false
      }
      state.currentCatalogeSelected = null;
    },
    resetCataloge_M(state) {
      state.currentCatalogeSelected = null;
    },
  }
};
