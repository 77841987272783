import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const catalogs = {
  namespaced: true,
  state: {
    allPlace:[],
    typeSites: [],
    event_config_desc: {
      5: "Evento con Mapa",
      4: "Fechas con cupo limitado",
      3: "Entrada Ilimitada",
      1: "Evento de redirección web"
    },
    place: {
      active: true,
      axesspas: "",
      axessusu: "",
      caja: 0,
      city: "",
      eventplace: "",
      id_client: 0,
      id_dependence: 0,
      id_place: 0,
      map: "",
      name: "",
      package: false,
      state: "",
      szloginid: "",
      urlaxess: "",
    },
    typeSite: {
      id: 0,
      name: "",
    },
  },
  mutations: {
    setAllPlacesM: (state, allPlaces) => {
      state.allPlace = [...allPlaces];
    },
    triggertypeSite: (state, eventCategories) => {
      eventCategories.forEach( category => {
        state.typeSites = [
          ...state.typeSites,
          {
            id: category.id_eventcata,
            name: category.ES,
          }
        ];
      })
    },
    cleantypeSite: (state) => {
      state.typeSites = [];
    },
    cleanallPlace: (state) => {
      state.allPlace = [];
    }
  },
  actions: {
    setAllPlaces: ({ commit }, places) => {
      commit("setAllPlacesM", places);
    },
    triggertypeSite: ({ commit }, payload) => {
      commit("triggertypeSite", payload);
    },
    cleantypeSite: ({ commit }) => {
      commit("cleantypeSite");
    },
    cleanallPlace: ({ commit }) => {
      commit("cleanallPlace");
    },
  },
  modules: {},
  getters: {
    getallPlace(state, id) {
      let allPlace = state.allPlace;
      return allPlace;
    },
    gettypeSites(state, id) {
      let typeSites = state.typeSites;
      return typeSites;
    },
    getEventConfig(state, id) {      
      let event_config_full = state.event_config_desc;
      return event_config_full;
    },
    getMapPlace: (state) => (id_placeX) => {
      return state.allPlace.find(({id_place}) => id_place === id_placeX)
    }
  },
};
