module.exports.messages = {
  welcome: 'Welcome!',
  validationx:'Starting charge validation',
  TransTickets:'Your transaction has',
  MaxTickets:'Limite de tickets par transaction',
  TokenInvalid:'Login or Register!',
  SelectTickets:'Select a ticket!',
  generateorden:'Generating order',
  finishtransac:'Finalizing transaction',
  confirmpay:'Confirming payment',
  invalidUser: 'Invalid user!',
  desactivate: 'Disabled for now',
  failedToLogin: 'Failed to login!',
  ticketsNumberMustZero: 'The number of tickets must be greater than zero',
  ticketsNumberMustLessHundred: 'The number of tickets must be less than one hundred',
  checkTheFormData: 'Check the form data',
  acceptYermsConditions: 'Accept the terms and conditions',
  validation: {
    required: 'This field is required.',
    isEmail: 'The email must be valid.',
    validCard: 'Invalid Card',
    isPhone: 'This phone is invalid',
    validLocation: 'Please select a location from the options',
    invalidFields: 'Invalid Fields',
    ValidateReCAPTCHA: 'Validate the reCAPTCHA',
    invalidCode: 'Invalid Code',
    InvalidEmailPassword: 'Invalid email or password'
  },
  tarjetValid:'Valid Card',
  permissionsRequired: 'Permissions required.',
  needEmailMsg: 'Email required. Do you want to grant the permissions?',
  tryAgain: 'Try again',
  canceltickets:'Want to remove tickets from this site?',
  UpdateInfoUser:'Updated data',
  msgConfirmation1:'¡PRINT YOUR TICKETS 48 HOURS AFTER YOUR PURCHASE! (before that time you will not be able to print them) and up to 72 hours before the event by entering your Ticket2pass account.',
  updatedPass: "Updated password",
  correctCode: "Correct code. Enter new password."
}