module.exports.navigation = {
  home: 'Inicio',
  centerTickets: 'Centros pasetotal',
  help: 'Ayuda',
  account: {
    me: 'Mi Cuenta',
    mytickets: {
      title: 'Mis Boletos',
      items: ['Próximos eventos', 'Eventos pasados', 'Mis boletos']
    },
    myProfile: 'Mi Perfil',
    Logout: 'Cerrar Sesión'
  },
  accountData: 'Datos de la cuenta',
}