module.exports.calendary = {
    Month:[
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Peut',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ],
    Day:[
        'Dimanche',
        'Lundi',
        'Mardi',
        'Mercredi',
        'Jeudi',
        'Vendredi',
        'Samedi',
    ]
}