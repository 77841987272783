import { actions } from "./actions.js";
import { home } from "./home.js";
import { login } from "./login.js";
import { navigation } from "./navigation.js";
import { messages } from "./messages.js";
import { tableHeaders } from "./tableHeaders.js";
import { sales } from "./sales";
import {calendary} from "./calendary"

export default {
	actions,
	home,
	login,
	navigation,
	messages,
	tableHeaders,
	sales,
	calendary,
}