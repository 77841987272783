module.exports.actions = {
  categorias:[
    {name:'Paradores Turisticos'},
    {name:'Eventos'}
  ],
  tickets:'Boleto',
  packets:'Paquetes',
  price:'Precio',
  amount:'Cantidad',
  place:'Sitio',
  SeeTickets:'Ver Boletos',
  logIn: 'Iniciar sesión',
  logInFacebook: 'Iniciar sesión con Facebook',
  Logout: 'Cerrar sesión',
  signIn: 'Registrarse',
  next: 'Siguiente',
  seeTickets: 'Ver boletos',
  goBack: 'Regresar',
  cancel: 'Cancelar',
  save: 'Guardar',
  change: 'Cambiar',
  toClose: 'Cerrar',
  search: 'Buscar',
  printTickets: 'Imprimir boletos',
  understand: 'Entiendo',
  nextEvent:'Proximos Eventos',
  findTicket:'Buscar Boletos',
  Events:'Evento(s)',
  EventS2:'EVENTOS',
  limitedAvai:'Disponibles',
  limitedAvai2:'Agotado',
  about:'Acerca de',
  ticketFor:'Boletos para',
  limitEdad:'Límite de edad: No hay restricciones. Pagan boleto a partir de: 3 años. Restricciones: Se prohíbe el ingreso con alimentos, bebidas, cámaras de video ...',
  cardinfo:{
    tickets:'Boleto(s)',
    ticketsUp: 'BOLETO(S)',
    section:'SECCION',
    row:'FILA',
    colum:'COLUMNA',
    seat:'ASIENTO',
    unitPrice: 'PRECIO UNITARIO',
    unitPrice2: 'Precio Unitario',
    price:'PRECIO',
    total: 'TOTAL',
    subtotal: 'SUBTOTAL',
    amount:'CANTIDAD',
    numberTickets: "Número de boletos"
  },
  step:{
    usuario:'Datos del Usuario',
    pay:'Datos del pago',
    confirma:'Confirmación de compra',
    confirma1:'CONFIRMAR',
    confirma2:'COMPRA',
  },
  paycard:{
    card:'No. Tarjeta',
    exp:'Expiración',
    requi:'Este campo es requerido',
    terminos:'Términos y condiciones',
    read:'He leído los',
  },
  pdf:{
    oops:"Oops! Tu navegador no soporta PDFs!",
    download:'Descargar Archivo'
  },
  next:'CONTINUAR',
  shop:'COMPRAR',
  information: 'Infomación',
  more:'Mas Informatión',
  address: 'Domicilio',
}