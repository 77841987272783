export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    name: 'Dashboard',
    redirect: "home",
    meta: {
        requiresAuth: true,
    },
    component: () => import('../views/DashboardMain.vue'),
    children: [
      //Componentes
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/dashboardmain/Home.vue'),
      },
      {
        path: 'events',
        name: 'Events',
        component: () => import('../views/dashboardmain/Events.vue'),
      },
      {
        path: '/event/:id',
        name: 'EventId',
        component: () => import('../components/events/EditEvent.vue'),
        props: true
      },
      {
        path: 'events-types',
        name: 'EventsTypes',
        component: () => import('../views/dashboardmain/catalogs/EventsTypes.vue'),
      },
      {
        path: 'netpay',
        name: 'Netpay',
        component: () => import('../views/dashboardmain/webshop/Netpay.vue'),
      },
      {
        path: 'theme-settings',
        name: 'ThemeSettings',
        component: () => import('../views/dashboardmain/webshop/ThemeSettingsView.vue'),
      },
      {
        path: 'reports',
        name: 'Reports',
        component: () => import('../views/dashboardmain/Reports.vue'),
      },
    ],
  },
  {
    path: '*',
    name: 'catchAll',
    redirect: {name: 'NotFound'},
    meta: {
        requiresAuth: true,
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import("../views/404PageView.vue"),
  },
];
