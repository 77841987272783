module.exports.actions = {
  categorias:[
    {name:'Tourist sites'},
    {name:'Events'},
  ],
  tickets:'Ticket',
  packets:'Packages',
  price:'Price',
  amount:'Amount',
  place:'Place',
  SeeTickets:'See Tickets',
  logIn: 'Log in',
  logInFacebook: 'Log in with facebook',
  Logout: 'Logout',
  signIn: 'Sign in',
  next: 'Next',
  seeTickets: 'see tickets',
  goBack: 'Back',
  cancel: 'Cancel',
  save: 'Save',
  change: 'Change',
  toClose: 'To close',
  search: 'Search',
  printTickets: 'Print tickets',
  understand: 'Okey, I understand', 
  nextEvent:'Upcoming Events',
  findTicket:'Find Ticket',
  Events:'Events',
  EventS2:'EVENTS',
  limitedAvai:'Available',
  limitedAvai2:'Sold out',
  about:'About',
  ticketFor:'Ticket for',
  limitEdad:'Age limit: There are no restrictions. They pay ticket from: 3 years. Restrictions: Entry with food, drinks, video cameras... is prohibited.',
  cardinfo:{
    tickets:'Ticket(s)',
    ticketsUp: 'TICKET(S)',
    section:'SECTION',
    row:'ROW',
    colum:'COLUMN',
    seat:'SEAT',
    unitPrice: 'UNIT PRICE',
    unitPrice2: 'Unit price',
    price:'PRICE',
    total: 'TOTAL',
    subtotal: 'SUBTOTAL',
    amount:'AMOUNT',
    numberTickets: "Number of Tickets"
  },
  step:{
    usuario:'User data',
    pay:'Details of payment',
    confirma:'Purchase confirmation',
    confirma1:'CONFIRM',
    confirma2:'PURCHASE',
  },
  paycard:{
    card:'Card No.',
    exp:'Expiration',
    requi:'This field is required',
    terminos:'Terms and Conditions',
    read:'I have read the',
  },
  pdf:{
    oops:"Oops! Your browser doesn't support PDFs!",
    download:'Download File'
  },
  next:'NEXT',
  shop:'BUY',
  information: 'Information',
  more:'More Information',
  address: 'Address Information',
}