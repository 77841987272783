module.exports.messages = {
  welcome: '¡Bienvenido!',
  validationx:'Iniciando validación de cargo',
  TransTickets:'Su transacción tiene',
  MaxTickets:'Limite de boletos por transacción',
  TokenInvalid:'Inicia sesión o Registrate!',
  SelectTickets:'Seleccione algun boleto!',
  generateorden:'Generando orden',
  finishtransac:'Finalizando transacción',
  confirmpay:'Confirmando pago',
  invalidUser: '¡Usuario inválido!',
  desactivate: 'Desactivado por el momento',
  failedToLogin: '¡Error al iniciar sesión!',
  ticketsNumberMustZero: 'El cantidad de boletos debe ser mayor que cero',
  ticketsNumberMustLessHundred: 'La cantidad de boletos debe ser menor a cien',
  checkTheFormData: 'Verifique los datos del formulario',
  acceptYermsConditions: 'Acepte los términos y condiciones',
  validation: {
    required: 'Este campo es obligatorio.',
    isEmail: 'El correo electrónico debe ser válido.',
    validCard: 'Tarjeta Inválida',
    isPhone: 'El teléfono es inválido',
    validLocation: 'Por favor, seleccione un lugar de las opciones',
    invalidFields: 'Campos Invalidos',
    ValidateReCAPTCHA: 'Valida el reCAPTCHA',
    invalidCode: 'Código no válido',
    InvalidEmailPassword: 'Correo o contraseña Invalido'
  },
  tarjetValid:'Tarjeta Valida',
  permissionsRequired: 'Se requiere permisos.',
  needEmailMsg: 'Correo electrónico necesarios. ¿Desea otorgar los permisos?',
  tryAgain: 'Intentar nuevamente',
  canceltickets:'Desea Eliminar los boletos de este sitio?',
  UpdateInfoUser:'Datos Actualizados',
  msgConfirmation1:'¡IMPRIME TUS BOLESTOS 48 HORAS DESPUES DE TU COMPRA! (antes de ese tiempo no podras imprimirlos) y hasta 72 horas antes del evento entrando a tu cuenta de Ticket2pass.',
  updatedPass: "Contraseña actualizada",
  correctCode: "Código correcto. Ingrese nueva contraseña."
}