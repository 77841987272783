module.exports.navigation = {
  home: 'Home',
  centerTickets: 'pasetotal centers',
  help: 'Help',
  account: {
    me: 'My account',
    mytickets: {
      title: 'My tickets',
      items: ['Upcoming events', 'Past events', 'My tickets']
    },
    myProfile: 'My profile',
    Logout: 'Logout'
  },
  accountData: 'Account data',
}