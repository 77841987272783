import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from '../store/auth-module';

// Routes
import paths from './paths'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: paths
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth) && !auth.state.user) {
    next('/login')
  }
  else if(to.name == 'login' && auth.state.user) { 
    next('/')
  }
  else {
    next()
  }
});

export default router;