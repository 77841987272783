import Vue from "vue";
import App from "./App2.vue";
import router from "./router";
import store from "./store";

// --------------- Estilos ------------------------
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "animate.css";
import 'vue-datetime/dist/vue-datetime.css';

// --------------- Componentes --------------------
import VueI18n from "vue-i18n";
import axios from "axios";
import VueCookies from "vue-cookies";
import VueHtml2Canvas from "vue-html2canvas";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { VueMaskDirective } from "v-mask";
import VueSession from "vue-session";
import VTiptapVue from "@peepi/vuetify-tiptap";
import Datetime from 'vue-datetime';



// --------------- languages --------------------
import MX from "./i18n/Spanish/index.js";
import US from "./i18n/English/index.js";
import FR from "./i18n/French/index.js";
import "./utils/global/entorno";
import "./utils/global/elements";

Vue.use(VueSession);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(VueSweetalert2);
Vue.use(VueHtml2Canvas);
Vue.use(Vuetify);
Vue.directive("mask", VueMaskDirective);
Vue.use(VTiptapVue);
Vue.use(Datetime);


const i18n = new VueI18n({
  locale: "mx",
  messages: {
    mx: MX,
    us: US,
    fr: FR,
  },
});

// Translation provided by Vuetify (javascript)
import es from "vuetify/lib/locale/es";
import en from "vuetify/lib/locale/en";

const opts = [
  {
    lang: {
      locales: { es },
      current: "es",
    },
  },
  {
    lang: {
      locales: { en },
      current: "en",
    },
  },
];
// let myTimeOut;

export default new Vuetify(opts[0]);

Vue.config.productionTip = false;

import { EVENTMIXIN } from '@/mixins/eventsMixin'

// import { jsPDF } from "jspdf";
// var QRCode = require("qrcode");

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  i18n,
  data: {
    showInfoToolbar: false,
    persmise:false,
    loading: true,
    token: null,
    // myserver: "//199.89.53.147",
    myserver: "//t2pdevelopment.com",   //192.190.42.149
    // myserver: "//192.168.1.68",
    // myserver: "//localhost",
    pathevens: "/axess/event/",
    pathgallery: "/axess/gallery/",
    pathLoyaut: "/axess/loyaut/",
    UrlImg: "/axess/place/",
    UrlCorpImg: "/axess/corpImages/",
    myport: "7771",
    myport1: "7771", // http
    myport2: "7772", // https
    langs: [
      { text: "Español", locale: "mx", abbreviation: "ES" },
      { text: "English", locale: "us", abbreviation: "EN" },
    ],
    MsgAlert:
      "Praesent congue erat at massa. Nullam vel sem. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Proin viverra, ligula sit amet ultrices semper, ",
  },
  mixins:[EVENTMIXIN],
  created() {
    //Detecta refresh
    window.addEventListener("beforeunload", this.handler);

    if (window.location.protocol == "https:") {
      this.UrlImg = "https:" + this.myserver + this.UrlImg;
      this.UrlCorpImg = "https:" + this.myserver + this.UrlCorpImg;
      this.pathevens = "https:" + this.myserver + this.pathevens;
      this.pathgallery = "https:" + this.myserver + this.pathgallery;
      this.pathLoyaut = "https:" + this.myserver + this.pathLoyaut;
      this.myserver = "https:" + this.myserver + ":";
      this.myport = this.myport2;
    } else {
      this.UrlImg = "http:" + this.myserver + this.UrlImg;
      this.UrlCorpImg = "http:" + this.myserver + this.UrlCorpImg;
      this.pathevens = "http:" + this.myserver + this.pathevens;
      this.pathgallery = "http:" + this.myserver + this.pathgallery;
      this.pathLoyaut = "http:" + this.myserver + this.pathLoyaut;
      this.myserver = "http:" + this.myserver + ":";
      this.myport = this.myport1;
    }
  },
  mounted() {},
  methods: {
    logOut() {
      this.$store.dispatch('auth/logOut')
      if(this.$route.name != "Login") this.$router.push({ name: "Login" });
    },
    verifiRedir(){
      let check = [...this.$store.state.auth.corps]
      if(this.$store.state.auth.user.admin === 1 && check.length == 0) {
        this.$router.push({ name: "Home" });
      }
      this.$root.showInfoToolbar = true;
    },
    handler: function handler(event) {
      // console.log("refresh");
    },
    getTextlang: function () {
      let nameLang = "";
      this.langs.forEach((lang) => {
        if (lang.locale === this.$i18n.locale) nameLang = lang.abbreviation;
      });
      return nameLang;
    },
    Toast: function (icon, title, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        width: 280,
        timer: 1000,
        timerProgressBar: true,
        icon: icon,
        title: title,
        text: text,
      });
    },
    swalAlert: function (icon, title, text) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
      });
    },
    confimDelay: function (icon, title, text, textConfirm) {
      var self = this;
      return new Promise((resolve, reject) => {
        this.$swal({
          title,
          text,
          icon,
          showDenyButton: true,
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "primary",
          confirmButtonText: textConfirm,
          denyButtonColor: "#dd8159",
          didOpen: function () {
            var b = self.$swal.getConfirmButton();
            b.disabled = true;

            var n = 5;
            for (let i = 0; i < 5; i++) {
              setTimeout(function () {
                b.textContent = textConfirm + ` (${n})`;
                n = n - 1;
              }, i * 1000);
            }

            setTimeout(function () {
              b.disabled = false;
              b.textContent = textConfirm;
            }, 5000);
          },
        })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    SetToken() {
      this.$cookies.remove("user");
      this.$cookies.set("user", this.token);
    },
    CleanLocalStorage() {
      localStorage.clear();
    },
    post: function (endPoint, data2) {
      let token = JSON.parse(localStorage.getItem("user"));
      if (token) {
        token = token.token_client;
      }
      
      const data = {...data2, id_corp: this.$store.state.auth.id_corp_selected }
      return new Promise((resolve, reject) => {
        axios
          .post(
            this.$root.myserver + this.$root.myport + endPoint,
            { data },
            {
              headers: {
                "X-Access-Token": token,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.swalAlert("error", error.response.data);
                return false;
              }
            } else {
              this.swalAlert("error", error.response.data);
              return false;
            }
            console.log(error);
            reject(error);
          });
      });
    },
    get: function (endPoint) {
      let token = JSON.parse(localStorage.getItem("user"));
      if (token) {
        token = token.token_client;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(this.$root.myserver + this.$root.myport + endPoint, {
            headers: {
              "X-Access-Token": this.$root.token.token_client,
            },
          })
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    nowtime() {
      var d = new Date();
      return d.getTime();
    },
    formatNum: function (value) {
      if (!value) {
        value = 0;
      }
      if (typeof value === "string") {
        value = parseFloat(value);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPrice: function (value) {
      if (!value) {
        value = 0;
      }
      if (typeof value === "string") {
        value = parseFloat(value);
      }
      value = value.toFixed(2);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    detectmobil() {
      return new Promise((resolve) => {
        let val = false;
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          val = true;
        }
        resolve(val);
      });
    },
  },
  render: (h) => h(App),
}).$mount("#app");
