module.exports.home = {
  footer: {
    connect: "Connectons-nous",
    toHelpYou: 'Nous sommes là pour vous aider',
    business: 'Entreprise',
    purchasePolicy: "Politique d'achat",
    noticePrivacy: 'Avis de confidentialité',
    allRightsReserved: 'Tous droits réservés',
  },
  footer2: {
    politicas:'Politiques de confidentialité',
    transparencia:'Transparence',
    cuenta:'Compte public',
    apartado:'Section intégrité',
    directorio:'Répertoire',
    programa:'Programmes et appels',
    ubication:'Emplacement :',
    mail:'E-mail :',
    tel:'Téléphone:',
    oficinaH:"Heures d'ouverture :",
    oficinaH2:'Du lundi au vendredi de 9h00 à 18h00',
    CinemaH:'Horaires cinéma:',
    CinemaH2:'Du lundi au dimanche',
    SiteInt:"Sites d'intérêt",
    Gob:'Gouvernement du Yucatan',
    NKukulkan:'Nuits de Kukulkan',
  }
}