<template>
  <v-app id="app" class="noselect" style="width: 100vw; height: 100vh;">
    <router-view />
  </v-app>
</template>

<script>
import $ from "jquery";
export default {
  name: "App",
  data() {
    return {
      Scroll: "orange",
    };
  },
  async mounted() {
    this.setColorApp();
    let token = JSON.parse(localStorage.getItem("user"));
    this.$root.token = token;
    let mobil = await this.$root.detectmobil();
    if (!mobil) {
      $("html").css({ overflow: "hidden" });
    }

    /*     document.addEventListener('keyup', function (evt) {
      console.log(evt.keyCode);
    }); */
  },
  methods: {
    setColorApp: function () {},
  },
};
</script>

<style scoped>
.MyScroll {
  overflow: auto !important;
  height: 300px !important;
} 

.MyLoading {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  height: 100 vh;
  width: 100 vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: #fafbfe;
}
.login {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: #e5e5e5;
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: #1976d2 transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(56, 56, 56) !important;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1976d2 !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0f3963 !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
</style>