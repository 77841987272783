module.exports.home = {
  footer: {
    connect: "Let's connect",
    toHelpYou: 'We are here to help you',
    business: 'Business',
    purchasePolicy: 'Purchase policy',
    noticePrivacy: 'Notice of privacy',
    allRightsReserved: 'All rights reserved',
  },
  footer2: {
    politicas:'Privacy policies',
    transparencia:'Transparency',
    cuenta:'Public account',
    apartado:'Integrity section',
    directorio:'Directory',
    programa:'Programs and calls',
    ubication:'Location:',
    mail:'Email:',
    tel:'Phone:',
    oficinaH:'Office Hours:',
    oficinaH2:'Monday to Friday 9:00 am-6:00 pm',
    CinemaH:'Cinema Hours:',
    CinemaH2:'Monday to Sunday',
    SiteInt:'Sites of Interest',
    Gob:'Government of Yucatan',
    NKukulkan:'Nights of Kukulkan',
  }
}