module.exports.login= {
  timeLeft: 'Time left',
  whatsThis: {
    title: 'What\'s this?',
    text: 'Time doesn\'t stop - This timer shows how long we will hold your tickets during the checkout process. If the time runs out, other users will have the opportunity to buy them. However, you will be able to buy your tickets later, if they are still available.',
  },
  PutName:'Enter name',
  PutLastName:'Enter your last name',
  street:'Street',
  areYouNew: 'Are you new to Ticekt2pass?',
  password: 'Password',
  name: 'Name',
  lastName: 'Last name',
  email: 'Email',
  phone: 'Phone',
  address: 'Address',
  postalCode: 'Postal Code',
  country: 'Country',
  state: 'State',
  city: 'City',
  rememberMe: 'Remember me',
  letMeKnow: 'Let me know about upcoming events and special offers!',
  letMeKnowMessage: 'You will receive information about us and our events. You may unsubscribe at any time',
  forgotPassword: 'Forgot your password?',
  legalInformation: {
    byContinuing: 'By continuing to the next page, you agree to the',
    terms: 'terms',
    and: 'and',
    conditions: 'conditions',
    andYouUnderstand: 'and understand that the information will be used as described in our',
    privacyPolicies: 'privacy policies.',
  },
  loginWithFacebook: 'Login with Facebook',
  signOutFacebook: 'Sign out of Facebook',
  doYouHaveAccount: 'Do you have a Ticket2pass account?',
  recoverPassword: 'Recover password',
  doYouNeddtoRecover: 'Do you need to recover your password? Enter your email to continue.',
  typeTheCode: 'A code was sent to your email, type it in to continue',
  code: 'Code',
  typeNewPassword: 'Please type your new password',
  backToLogin: 'Back to login',
}